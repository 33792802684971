import { graphql } from 'gatsby' 
import React, { useState, useEffect } from 'react'

import { ListingIndexQueryQuery, ListingByPathQuery } from '../../types/graphql-types'
import Listing from '../templates/listing/listing'
import Meta from '../components/meta/meta'
import Layout from '../components/layout/layout'
import Form from 'react-bootstrap/Form'

import Helmet from "react-helmet"
import { NavItem } from 'react-bootstrap'
interface Props {
  data: ListingIndexQueryQuery
  location: Location
}

const ListingIndex: React.FC<Props> = ({ data, location }: Props) => {
  const listings = data.allListings.listings
  console.log(listings)
  const meta = data.allListings.meta
  // get a list of all clients
  const clients = data.allListings.listings
  
  // map unique values from the clients variable
  let uniqueClients = [...new Set(clients.map((item: { listing: { client_name: any } }) => item.listing.client_name))];
    
  // console.log(clients)
  //console.log(uniqueClients.flatMap(s => s.split(" | ")))
  
  uniqueClients = uniqueClients.flatMap(s => s.split(" | ")) 

  uniqueClients = [...new Set(uniqueClients)]
  
  const [filterParamClient, setFilterParamClient] = useState("");

  // This allows us to persist state across pages
  useEffect(() => {
    //console.log(JSON.parse(localStorage.getItem('submissionData')))
    const savedClientFilter = localStorage.getItem('filterParamClient')
    if (savedClientFilter != null) {
      setFilterParamClient(savedClientFilter)
    }

  }, []);

  function handleClick(newValue: React.SetStateAction<string>) {
    setFilterParamClient(newValue)
    // set localStorage to persist the setting on reload
    localStorage.setItem('filterParamClient', newValue)
  }


  return (
    <Layout location={location}>
      <Meta site={meta} title="Listings"/>
      <Helmet
        bodyAttributes={{
          class: "page-listings",
        }}
      />
      <div className="container px-6">
        <section>
          <div className="container">
            <h1>Listings</h1>
            <p>DrJ/CBI listings provide third party verification that a product meets the requirements of specified standards and may also provide information regarding design values derived from such testing, installation or other considerations regarding use of the product. Product listings are subject to periodic testing and inspection protocols to monitor the products compliance with the specified standard(s) on an on-going basis. </p>

            {/* Map over the list of available clients to create a filter. */}
            {clients &&
              <Form id="listing-filters">
                <Form.Group className="form-group">
                  <Form.Label className="form-label sr-only" htmlFor="client-select">Select a report holder to view listings:</Form.Label>  
                  <Form.Control
                    as="select" 
                    onChange={(e) => {
                      handleClick(e.target.value);
                    }}
                    className="form-control form-select mt-0"
                    aria-label="Filter by Client"
                    value={filterParamClient}
                    id="client-select"
                    size="lg"
                  >
                    <option value="">Please select a report holder to view available listings</option>
                    {clients && uniqueClients.sort((a, b) => a < b ? -1 : 1).map((item, key) => (
                      <option key={key} value={item}>{item}</option>
                    ))}
                  </Form.Control>
                </Form.Group>
              </Form>
            }

            {filterParamClient &&            
              <h2>We found {listings.filter((listing: { listing: { client_name: string | string[] } }) => listing.listing.client_name.includes(filterParamClient)).length} Listing{listings.filter((listing: { listing: { client_name: string } }) => listing.listing.client_name.includes(filterParamClient)).length > 1 && 's' } for {filterParamClient}</h2>
            }
            
            <div className="results">
              {listings.filter((listing: { listing: { client_name: string | string[] } }) => {
                return listing.listing.client_name.includes(filterParamClient)
              }).map((listing: any, i: React.Key | null | undefined) => (
                
                  <Listing
                    data={listing as ListingIndexQueryQuery}
                    options={{
                      isIndex: true,
                    }}
                    key={i}
                  />
                
              ))}
            </div>
          </div>
        </section>
      </div>
    </Layout>
  )
}

export default ListingIndex

export const pageQuery = graphql`
  query ListingIndexQuery {
    allListings(
      filter: {report_type: {in: ["730", "750"]}}
      limit: 1000
      sort: {fields: no, order: ASC}
    ) {
      meta: nodes {
        no
        title
        alias
      }
      listings: edges {
        listing: node {
          no
          title
          path: alias
          summary
          client_name
          product_name
          issue_date
          updated_date
          nid
          qr_path
          url
        }
      }
    }
  }
`
